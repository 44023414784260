<template>
  <section>
    <transition name="page" mode="out-in">
      <div id="wrapper" :key="currentTime" v-if="!loadingAuthUuid">
        <Header v-if="showHeader"></Header>
          <ErrorValidations></ErrorValidations>
          <div v-if="showDebug" v-html="log" style="font-size: 9px; background: #000; color: #fff; padding: 5px; height: 300px; width: 100%; overflow: auto;"></div>
          <div id="page-content" v-if="!connection">
            <div class="p-4">
              <InformationMessage type="error">
                <i class="fal fa-exclamation-circle"></i>
                {{ $t("ERROR.NETWORK") }}
              </InformationMessage>
              <div class="p-4">
                {{ $t("NO_CONNECTION") }}
              </div>
            </div>
          </div>
          <div id="page-content" v-if="connection">
            <b-overlay :show="loading" rounded="sm">
              <div v-if="!loading">
                <div v-if="user.hostname == ''">
                  <NotRegistered></NotRegistered>
                </div>
                <div v-if="user.hostname !== '' && 1 == 0">
                  <DoNotUse></DoNotUse>
                </div>
                <div v-if="user.hostname !== ''">
                  <div v-if="outlookEnabled">
                    <Login v-if="showLogin"></Login>
                    <div v-if="userInformation">
                      <Read v-if="showRead"></Read>
                      <Compose v-if="showCompose"></Compose>
                    </div>
                  </div>
                  <ReadDialog v-if="readDialog && checkValidVersion('4.2.0')" :message_uuid="message_uuid" ></ReadDialog>
                  <ReadDialog_4_2 v-if="readDialog && !checkValidVersion('4.3.0') && checkValidVersion('4.2.0')" :message_uuid="message_uuid"></ReadDialog_4_2>
                  <ReadDialog_4_1
                    v-if="readDialog && !checkValidVersion('4.2.0')"
                    :message_uuid="message_uuid"
                  ></ReadDialog_4_1>
                  <ComposeDialog v-if="composeDialog && checkValidVersion('4.3.0')"></ComposeDialog>
                  <ComposeDialog_4_2 v-if="composeDialog && !checkValidVersion('4.3.0') && checkValidVersion('4.2.0')"></ComposeDialog_4_2>
                  <ComposeDialog_4_1 v-if="composeDialog && !checkValidVersion('4.2.0')"></ComposeDialog_4_1>
                </div>
              </div>
            </b-overlay>
          </div>
        <Footer v-if="showFooter"></Footer>
      </div>
    </transition>
  </section>
</template>
<script>
import outlookMethods from "@/mixins/outlookMethods";
import localMethods from "@/mixins/localMethods";
import ewsMethods from "@/mixins/ewsMethods";
import restMethods from "@/mixins/restMethods";
import URLSearchParams from "@ungap/url-search-params";
export default {
  mixins: [outlookMethods, localMethods, ewsMethods, restMethods],
  components: {
    DoNotUse: () => import("@/components/DoNotUse"),
    NotRegistered: () => import("@/components/NotRegistered"),
    Header: () => import("@/components/Layout/Header"),
    Footer: () => import("@/components/Layout/Footer"),
    Login: () => import("@/components/Login"),
    Read: () => import("@/components/Read"),
    Compose: () => import("@/components/Compose"),
    ReadDialog_4_1: () => import("@/components/ReadDialog_4_1"),
    ReadDialog_4_2: () => import("@/components/ReadDialog_4_2"),
    ReadDialog: () => import("@/components/ReadDialog"),
    ComposeDialog_4_1: () => import("@/components/ComposeDialog_4_1"),
    ComposeDialog_4_2: () => import("@/components/ComposeDialog_4_2"),
    ComposeDialog: () => import("@/components/ComposeDialog"),
    InformationMessage: () => import("@/components/Layout/InformationMessage"),
    ErrorValidations: () => import("@/components/ErrorValidations")
},
  data() {
    return {
      loadingAuthUuid: true,
      debugmails: null,
      context: null,
      readDialog: false,
      composeDialog: false,
      dialogEmail: "",
      currentTime: Date.now(),
      outlookEnabled: false,
      addinClient: "",
      actionMessage: this.$t("LOADING"),
      action: "",
      loading: true,
      addinUrl: process.env.VUE_APP_ADDIN_URL,
      message_uuid: "",
    };
  },
  methods: {
    async init() {
      this.loadDebugMails();
      let uri = window.location.search.substring(1);
      this.$store.dispatch("logger/add", "init uri:" + uri);
      let params = new URLSearchParams(uri);
      this.loading = true;
      this.action = params.get("action");
      let auth_uuid = params.get("auth_uuid");
      this.message_uuid = decodeURI(params.get("message_uuid"));
      if (auth_uuid == null) {
        this.loadingAuthUuid = false;
      }
      if (auth_uuid != null) {
        await this.loadOutlook();
        var messageObject = {
          messageType: "returnedBack",
          auth_uuid: auth_uuid,
        };
        var jsonMessage = JSON.stringify(messageObject);
        this.$Office.context.ui.messageParent(jsonMessage);
      } else if (this.action == "readDialog") {
        this.$store.dispatch("logger/add", "readDialog");
        let dialogEmail = params.get("email");
        await this.$store.dispatch("user/setEmail", dialogEmail);
        await this.$store.dispatch("user/fetchHostname");
        await this.$store.dispatch("user/fetchUser");
        this.loading = false;
        this.readDialog = true;
        return true;
      } else if (this.action == "composeDialog") {
        this.$store.dispatch("logger/add", "composeDialog");
        let dialogEmail = params.get("email");
        await this.$store.dispatch("user/setEmail", dialogEmail);
        await this.$store.dispatch("user/fetchHostname");
        await this.$store.dispatch("user/fetchUser");
        this.loading = false;
        this.composeDialog = true;
        return true;
      } else {
        this.$store.dispatch("logger/add", "action null");
        this.loadOutlook();
        this.loadItemChanged();
        if(this.$Office.context.mailbox != undefined)
        {
          let email = this.$Office.context.mailbox.userProfile.emailAddress;
          if (email == undefined) {
            email = await this.outlookGetEmail();
          }
          await this.$store.dispatch("user/setEmail", email);
          await this.$store.dispatch("user/fetchHostname");
          await this.$store.dispatch("user/fetchUser");
        }
        this.loading = false;
      }
    },
    async loadOutlook() {
      if (this.$Office != undefined) {
        this.context = this.$Office.context;
        this.$store.dispatch("logger/add", "context:" + this.context);
        this.outlookEnabled = true;
        this.$store.dispatch("logger/add", "outlookEnabled true");
      } else {
        this.$store.dispatch("logger/add", "$office undefined");
      }
    },
    async loadItemChanged()
    {
      this.$store.dispatch("logger/add", "ItemChanged");
      if(this.$Office.context.mailbox != undefined)
      {
        this.$Office.context.mailbox.addHandlerAsync(
          this.$Office.EventType.ItemChanged,
          this.itemChanged
        );
        this.$store.dispatch("logger/add", "ItemChanged true");
      }
    },
    itemChanged() {
      this.$store.dispatch("logger/add", "itemChanged");
      this.currentTime = Date.now();
      this.init();
    },
    async loadDebugMails() {
      let result = await this.$http.get("debugmails.json");
      this.debugmails = result.data.mails;
    }
  },
  computed: {
    showFooter() {
      if (
        this.action == "composeDialog" ||
        this.action == "readDialog" ||
        this.action == "sdkDialog"
      ) {
        return false;
      } else {
        return true;
      }
    },
    showHeader() {
      if (
        this.action == "composeDialog" ||
        this.action == "readDialog" ||
        this.action == "sdkDialog"
      ) {
        return false;
      } else {
        return true;
      }
    },
    showLogin() {
      return this.user.information == null;
    },
    showRead() {
      if (this.$Office.context.mailbox == undefined) {
        return false;
      } else {
        if (
          this.user.information !== null &&
          this.$Office.context.mailbox.item.displayReplyForm != undefined
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    showCompose() {
      //if (Office.context.requirements.isSetSupported('Mailbox', '1.8')) {
      if (this.$Office.context.mailbox == undefined) {
        return false;
      } else {
        if (
          this.user.information !== null &&
          this.$Office.context.mailbox.item.displayReplyForm == undefined
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    userInformation() {
      return this.user.information;
    },
    connection() {
      return this.user.connection;
    },
    log() {
      return this.$store.state.logger.log;
    },
    showDebug() {      
      if (process.env.VUE_APP_DEBUG == 1) {
        return true;
      } else {
        if (this.user.email) {
          if (this.debugmails) {
            if (this.debugmails.length != 0) {
              if (this.debugmails.includes(this.user.email)) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          }else{
            return false;
          }
        }else{
          return false;
        }
      }
    },
  },
  watch: {
    userInformation: function(val) {
      if (val == null) {
        this.$store.dispatch("logger/add", "user information null");
      } else {
        this.$store.dispatch("logger/add", "user information not null");
      }
    },
  },
  mounted() {
    this.loading = true;
    if (this.$Office.context.displayLanguage == "sv-SE") {
      this.$i18n.locale = "sv";
      this.$store.dispatch("lang/setLocale", "sv");
    } else {
      this.$i18n.locale = "en";
      this.$store.dispatch("lang/setLocale", "en");
    }
    this.init();
  },
};
</script>
