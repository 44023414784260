"use strict";
import Vue from 'vue'
import 'core-js/stable';
import 'regenerator-runtime/runtime'; 
import App from './App.vue'
import Axios from 'axios'
import Nl2br from "vue-nl2br";
import VueNoty from 'vuejs-noty'
import BootstrapVue from 'bootstrap-vue';
import store from './store.js';
import * as constants from './constants.ts';
import vSelect from 'vue-select'
Vue.component('v-select', vSelect);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import './sass/app.scss';
import i18n from './i18n';

Axios.defaults.withCredentials = true;

const compare = require('semver-compare');
Vue.prototype.$compare = compare;

import '@/mixins/generalMixin'

Axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  if(config.url.includes("/server/rest/"))
  {
      let token = Vue.$cookies.get("token");
      if(token != undefined)
      {
          if(token != "")
          {
              config.headers.Authorization =  token ? `Bearer ${token}` : '';
          }
      }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

Axios.interceptors.response.use(
    (response) => {         
        if(response.data.responseObject != undefined)
        {
            if(response.data.validations.validations.length != 0)
            {
                store.dispatch("errors/addValidations", response.data.validations.validations);
            }
            if(response.data.status == "Error"){
                return Promise.reject(new Error('fail_4_4'));           
            }            
            response.data = response.data.responseObject;
        }    
        return response;        
    },
    (error) => {

      if(error.response)
      {
        if (error.response.status === 401) {
            store.dispatch('logger/add','ErrorRequest 401');
            store.dispatch('user/clearUserInformation');
        }else{
          if (error.response.status === 400) {
              if(error.response.data.validations != undefined)
              {
                  if(error.response.data.validations.validations.length != 0)
                  {
                      store.dispatch("errors/addValidations", error.response.data.validations.validations);
                      for(let ix = 0; ix < error.response.data.validations.validations.length; ix++)
                      {
                          if(error.response.data.validations.validations[ix].validation == "MISSING_TOKENINFORMATION")
                          {
                            store.dispatch('logger/add','ErrorRequest 401');
                            store.dispatch('user/clearUserInformation');
                          }
                      }
                  }
                  return Promise.reject(new Error('fail_4_4'));
              }            
          }else{

            if(error.response.data != undefined)
            {
              store.dispatch('logger/add','ErrorRequest:' + error.response.data);
            }
            if(error.response.status != undefined)
            {
              store.dispatch('logger/add','ErrorRequest:' + error.response.status);
            }
            if(error.response.headers != undefined)
            {
              store.dispatch('logger/add','ErrorRequest:' + error.response.headers);
            }
          }
        }
      } else if (error.request) {
        store.dispatch('logger/add','Connection problem');
        store.dispatch('user/noConnection');  
        return Promise.reject(error);
      }
      store.dispatch('logger/add',error.message);
      return Promise.reject(error);
    }
);

Vue.use(BootstrapVue);

const { DateTime, Interval } = require("luxon");
Vue.prototype.$luxonDateTime = DateTime;
Vue.prototype.$luxonInterval = Interval;

Vue.prototype.$http = Axios;
Vue.prototype.axios = Axios;
Vue.prototype.$const = constants;


Vue.use(VueNoty, {
    theme: 'relax',
    timeout: 2000,
    progressBar: true,
    layout: 'topRight'
});
Vue.component('nl2br', Nl2br);

const Office = window.Office;
Vue.prototype.$Office = Office;

Office.onReady(function(info) {
    Vue.prototype.$hostInformation = info;
    new Vue({
        store,
        i18n,
        render: h => h(App)
    }).$mount('#app');
});