export default {
  data: function () {
    return {
      ews_token: ''
    }
  },
  created: function () {
    this.$store.dispatch("logger/add","init ewsMethods");
  },
  computed: {
  },
  methods: {
    getEwsToken: async function () {
      let self = this;
      this.$store.dispatch("logger/add","getTokenBearer");
      if(this.ews_token != "")
      {
        return this.token_bearer;
      }
      return new Promise((resolve) => {
        self.$Office.context.mailbox.getCallbackTokenAsync(
          { isRest: false },
          function (asyncResult) {
            console.log(asyncResult);
            if (asyncResult.status === "succeeded") {
              self.ews_token = asyncResult.value;
              resolve(asyncResult.value);
            } else {
              self.ews_token = '';
              resolve("");
            }
          }
        );
      });
    },
    ewsRequest: async function(xml)
    {
      var mailbox = this.$Office.context.mailbox;
      return new Promise((resolve) => {     
        mailbox.makeEwsRequestAsync(xml, function(result){
          let xmlResult = new DOMParser().parseFromString(result.value, 'text/xml');
          resolve(xmlResult);
        });
      });
    },
    ewsGetMessage: async function(mailItemId) {
      let self = this;
      try {
        this.$store.dispatch("logger/add","ewsGetMessageInformation");   
        let ewsId = this.$Office.context.mailbox.convertToEwsId(mailItemId, this.$Office.MailboxEnums.RestVersion.v2_0);  
        var xmlData = '<?xml version="1.0" encoding="utf-8"?>' +
          '<soap:Envelope' +
          '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"' +
          '  xmlns:xsd="http://www.w3.org/2001/XMLSchema"' +
          '  xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"' +
          '  xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
          '  <soap:Header>' +
          '     <t:RequestServerVersion Version="Exchange2013_SP1" />' +
          '  </soap:Header>' +
          '  <soap:Body>' + 
          '    <GetItem' +
          '      xmlns="http://schemas.microsoft.com/exchange/services/2006/messages"' +
          '      xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
          '      <ItemShape>' +
          '        <t:BaseShape>AllProperties</t:BaseShape>' +
          '      </ItemShape>' +
          '      <ItemIds>' +
          '        <t:ItemId Id="' + ewsId + '" />' +
          '      </ItemIds>' +
          '    </GetItem>' +
          '  </soap:Body>' +
          '</soap:Envelope>';
        let xmlResult = await this.ewsRequest(xmlData);
        this.$store.dispatch("logger/add","ewsGetAttachment success"); 
        let information = xmlResult.getElementsByTagName("t:Message")[0];
        return information;
      } catch (error) {
        console.log(error);
        self.$store.dispatch("logger/add","ewsGetMessageInformation error");
        return null;
      }        
    },
    ewsGetMessageInformation: async function(mailItemId) {
      this.$store.dispatch("logger/add","ewsGetMessageInformation");
      try {
        let mailInformation = await this.ewsGetMessage(mailItemId);
        let ToRecipients = [];
        let CcRecipients = [];
        let BccRecipients = [];
        if(mailInformation.getElementsByTagName("t:ToRecipients").length != 0)
        {
          let toObj = mailInformation.getElementsByTagName("t:ToRecipients")[0].getElementsByTagName("t:Mailbox");
          for(let tr= 0; tr < toObj.length; tr++)
          {
            let ToRecipient = toObj[tr];
            ToRecipients.push({
              EmailAddress: {
                Address: ToRecipient.getElementsByTagName("t:EmailAddress")[0].childNodes[0].nodeValue,
                Name: ToRecipient.getElementsByTagName("t:Name")[0].childNodes[0].nodeValue
              }            
            });
          }
        }
        if(mailInformation.getElementsByTagName("t:CcRecipients").length != 0)
        {
          let ccObj = mailInformation.getElementsByTagName("t:CcRecipients")[0].getElementsByTagName("t:Mailbox");
          for(let tr= 0; tr < ccObj.length; tr++)
          {
            let CcRecipient = ccObj[tr];
            CcRecipients.push({
              EmailAddress: {
                Address: CcRecipient.getElementsByTagName("t:EmailAddress")[0].childNodes[0].nodeValue,
                Name: CcRecipient.getElementsByTagName("t:Name")[0].childNodes[0].nodeValue
              }            
            });
          }        
        }
        if(mailInformation.getElementsByTagName("t:BccRecipients").length != 0)
        {
          let bccObj = mailInformation.getElementsByTagName("t:BccRecipients")[0].getElementsByTagName("t:Mailbox");       
          for(let tr= 0; tr < bccObj.length; tr++)
          {
            let BccRecipient = bccObj[tr];
            BccRecipients.push({
              EmailAddress: {
                Address: BccRecipient.getElementsByTagName("t:EmailAddress")[0].childNodes[0].nodeValue,
                Name: BccRecipient.getElementsByTagName("t:Name")[0].childNodes[0].nodeValue
              }            
            });
          }        
        }
        this.$store.dispatch("logger/add","ewsGetMessageInformation success");      
        console.log(mailInformation);  
        let result = {
          Subject: mailInformation.getElementsByTagName("t:Subject")[0].childNodes[0].nodeValue,
          ToRecipients: ToRecipients,
          CcRecipients: CcRecipients,
          BccRecipients: BccRecipients,
          HasAttachments: mailInformation.getElementsByTagName("t:HasAttachments")[0].childNodes[0].nodeValue,
          Id: mailInformation.getElementsByTagName("t:ItemId")[0].getAttribute("Id"),
          Body: {
            ContentType: mailInformation.getElementsByTagName("t:Body")[0].getAttribute("BodyType").toLowerCase(),
            Content: mailInformation.getElementsByTagName("t:Body")[0].childNodes[0].nodeValue
          } 
        }
        return result;
      } catch (error) {
        this.$store.dispatch("logger/add","ewsGetMessageInformation error");
        return null;
      }        
    },    
    ewsGetAttachments: async function(mailItemId) {
      this.$store.dispatch("logger/add","ewsGetAttachments");
      let attachments = [];
      try {
        let mailInformation = await this.ewsGetMessage(mailItemId);
        if(mailInformation.getElementsByTagName("t:FileAttachment").length != 0)
        {
          let fileObj = mailInformation.getElementsByTagName("t:FileAttachment");
          for(let tr= 0; tr < fileObj.length; tr++)
          {
            let file = fileObj[tr];
            let attachmentId = file.getElementsByTagName("t:AttachmentId")[0].getAttribute("Id");
            let content = await this.ewsGetAttachment(attachmentId);
            attachments.push({
              Id: attachmentId,
              Name: file.getElementsByTagName("t:Name")[0].childNodes[0].nodeValue,
              Size: file.getElementsByTagName("t:Size")[0].childNodes[0].nodeValue,
              Content: content
            });
          }
        }
        return attachments;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("logger/add","ewsGetAttachments error");
        return null;
      }        
    },
    ewsGetAttachment: async function(attachmentId) {
      try {
        this.$store.dispatch("logger/add","ewsGetAttachment");

        let token = await this.getEwsToken();
        console.log(this.ews_token);

        const exch = new this.$EwsJS.ExchangeService(this.$EwsJS.ExchangeVersion.Exchange2016)
        exch.Credentials = new this.$EwsJS.OAuthCredentials(token)
        exch.Url = new this.$EwsJS.Uri(this.$Office.context.mailbox.ewsUrl)
        //this.$EwsJS.EwsLogging.DebugLogEnabled = true;

       
        const attachment = await exch.GetAttachments([attachmentId], 1, null);
        console.log(attachment);

        /*
        const itemAttachment = attachments.Responses[0].Attachment;
        await itemAttachment.Load([this.$EwsJS.ItemSchema.MimeContent]);
        console.log(itemAttachment.FileAttachment.base64Content)
        */
        
        /*var xmlData = '<?xml version="1.0" encoding="utf-8"?>' +
        '<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"' +
        ' xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"' +
        ' xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
        '  <soap:Header>' +
        '     <t:RequestServerVersion Version="Exchange2013_SP1" />' +
        '  </soap:Header>' +
        '  <soap:Body>' +
        '     <GetAttachment xmlns="http://schemas.microsoft.com/exchange/services/2006/messages" xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
        '      <AttachmentShape/>' +
        '      <AttachmentIds>' +
        '        <t:AttachmentId Id="' + attachmentId + '"/>' +
        '      </AttachmentIds>' +
        '    </GetAttachment>' +
        '  </soap:Body>' +
        '</soap:Envelope>';
        let xmlResult = await this.ewsRequest(xmlData);
        console.log(xmlResult);
        this.$store.dispatch("logger/add","ewsGetAttachment success");        
        let attachment = xmlResult.getElementsByTagName("t:FileAttachment")[0];
        return {
          Name: attachment.getElementsByTagName("t:Name")[0].childNodes[0].nodeValue,
          ContentType: attachment.getElementsByTagName("t:ContentType")[0].childNodes[0].nodeValue,
          ContentBytes: attachment.getElementsByTagName("t:Content")[0].childNodes[0].nodeValue
        }*/



      } catch (error) {
        console.log(error);
        this.$store.dispatch("logger/add","ewsGetAttachment error");
        return null;
      }     
    }
  }
};