export default {
  data: function () {
    return {
      token_bearer: ''
    }
  },
  created: function () {
    this.$store.dispatch("logger/add","init outlookMethods");
    this.$store.dispatch("logger/add","outlookMethods useRest: " + this.useRest);
  },
  computed: {
    useRest: function()
    {
      return true;
/*      if(this.$Office.context.mailbox.restUrl == undefined)
      {
        return false;
      }else{
        return true;
      }*/
    }
  },
  methods: {
    getRestUrl: async function (mailItemId) {      
      this.$store.dispatch("logger/add","getRestUrl");  
      let self = this;
      return new Promise((resolve) => {        
        if (mailItemId == undefined) {
          this.$store.dispatch('logger/add',"getRestUrl: " + self.$Office.context.mailbox.restUrl + "/v2.0/me/");
          resolve(self.$Office.context.mailbox.restUrl + "/v2.0/me/");
        } else {
          if (self.$Office.context.mailbox.diagnostics.hostName !== 'OutlookIOS') {
            mailItemId = self.$Office.context.mailbox.convertToRestId(
              mailItemId,
              self.$Office.MailboxEnums.RestVersion.v2_0
            );
            let url = self.$Office.context.mailbox.restUrl + "/v2.0/me/messages/" + mailItemId;
            this.$store.dispatch("logger/add","url: " +  url);  
            resolve(url);
          } else {
            this.$store.dispatch('logger/add',"getRestUrl: " + self.$Office.context.mailbox.restUrl + "/v2.0/me/");
            resolve(self.$Office.context.mailbox.restUrl + "/v2.0/me/messages/" + mailItemId);
          }
        }
      });
    },
    getTokenBearer: async function () {
      let self = this;
      this.$store.dispatch("logger/add","getTokenBearer");
      if(this.token_bearer != "")
      {
        return this.token_bearer;
      }
      if(this.useRest)
      {
        return new Promise((resolve) => {
          self.$Office.context.mailbox.getCallbackTokenAsync(
            { isRest: true },
            function (asyncResult) {
              if (asyncResult.status === "succeeded") {
                self.token_bearer = asyncResult.value;
                resolve(asyncResult.value);
              } else {
                self.token_bearer = '';
                resolve("");
              }
            }
          );
        });
      }else{
        return new Promise((resolve) => {
          self.$Office.context.mailbox.getCallbackTokenAsync(
            { isRest: false },
            function (asyncResult) {
              if (asyncResult.status === "succeeded") {
                self.token_bearer = asyncResult.value;
                resolve(asyncResult.value);
              } else {
                self.token_bearer = '';
                resolve("");
              }
            }
          );
        });
      }
    },
    outlookGetMessageInformation: async function (action, mailItemId) {
      this.$store.dispatch("logger/add","outlookGetMessageInformation");
      if (this.action == "compose") { 
        await this.outlookGetMessageId();
      }
      await this.getTokenBearer();  
      let result = await this.outlookGetMessage(action,mailItemId);
      return {
        Subject: result.Subject,
        HasAttachments: result.HasAttachments,
        ToRecipients: result.ToRecipients,
        CcRecipients: result.CcRecipients,
        BccRecipients: result.BccRecipients,
        Id: result.Id,
        Body: {
          ContentType: result.Body.ContentType,
          Content: result.Body.Content
        }
      }
    },
    outlookGetMessage: async function (action, mailItemId, iteration = 0) {
      iteration++;
      let max_iteration = 30; 
      if(this.$Office.context.diagnostics.platform != "OfficeOnline"){
        await new Promise(resolve => setTimeout(resolve,1000));
      }
      if(iteration > max_iteration){
        return null;
      }
      try {
        this.$store.dispatch("logger/add","outlookGetMessageInformation");
        if (this.action == "compose") { 
          await this.outlookGetMessageId();
        }
        await this.getTokenBearer();  
        let result = null;
        if(this.useRest)
        {      
          result = await this.restGetMessageInformation(mailItemId);  
        }else{
          result = await this.ewsGetMessageInformation(mailItemId);                 
        }     
        if(result == null)
        {
          return await this.outlookGetMessage(action,mailItemId,iteration);
        }else{
          return result;
        }
      }catch(error)
      {
        console.log(error);
        if(error.response != undefined){
          console.log(error.response);
          if(error.response.message != undefined){
            this.$noty.error(error.response.message);
          }
        }
      }
      
    },
    outlookGetAttachments: async function (mailItemId) {
      this.$store.dispatch("logger/add","outlookGetAttachments");
      let attachments = [];
      await this.getTokenBearer();
      if(this.token_bearer == "")
      {
        throw "empty_token";
      }
      let result = null;
      if(this.useRest)
      {      
        result = await this.restGetAttachments(mailItemId);  
      }else{
        result = await this.ewsGetAttachments(mailItemId);                 
      }    
      if(result != null)
      {
        for(let tr= 0; tr < result.length; tr++)
        {
          attachments.push({
            Id: result[tr].Id,
            Name: result[tr].Name,
            Size: result[tr].Size
          });
        }
      }
      return attachments;
    },
    outlookGetInlineAttachments: async function (mailItemId) {
      this.$store.dispatch("logger/add","outlookGetAllAttachments");
      let attachments = [];
      await this.getTokenBearer();
      if(this.token_bearer == "")
      {
        throw "empty_token";
      }
      let result = [];
      if(this.useRest)
      {      
        result = await this.restGetInlineAttachments(mailItemId);  
      }else{
        //result = await this.ewsGetAttachments(mailItemId);                 
      }    
      if(result != null){
        for(let tr= 0; tr < result.length; tr++)
        {
          attachments.push({
            Id: result[tr].Id,
            Name: result[tr].Name,
            Size: result[tr].Size
          });
        }
      }
      return attachments;
    },
    outlookGetAttachment: async function (mailItemId, attachmentId) {
      this.$store.dispatch("logger/add","outlookGetAttachment");
      await this.getTokenBearer();    
      let result = null;              
      if(this.useRest)
      {      
        result = await this.restGetAttachment(mailItemId,attachmentId);  
      }else{
        result = await this.ewsGetAttachment(attachmentId);                 
      }               
      return {
        Name: result.Name,
        ContentType: result.ContentType,
        ContentBytes: result.ContentBytes,
        ContentId: result.ContentId
      }
    },
    outlookGetEmail: async function () {
      this.mailItemId = await this.outlookGetMessageId();
      this.$store.dispatch("logger/add","outlookGetEmail");
      await this.getTokenBearer();    
      let result = null;              
      if(this.useRest)
      {      
        result = await this.restGetEmail();
      }        
      return result;
    },
    outlookGetMessageId: async function () {
      this.$store.dispatch("logger/add","outlookGetMessageId");                                
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.saveAsync(function (asyncResult) {
          if (asyncResult.status === "succeeded") {
            resolve(asyncResult.value);
          } else {
            resolve("");
          }
        });
      });
    },
    outlookGetFolder: async function () {
      this.$store.dispatch("logger/add","outlookGetFolder");    
      this.getTokenBearer();                      
      try {
        let apiurl = await this.getRestUrl();
        let result = await this.$http.get(
          apiurl + "/MailFolders/Drafts",
          {
            withCredentials: false,
            headers: { Authorization: "Bearer " + this.token_bearer }
          }
        );
        return result.data;
      } catch (error) {
        this.$store.dispatch("logger/add","ERROR outlookGetFolder");
        return "";
      }   
    },
    outlookMoveMail: async function (mailItem, folder) {
      this.$store.dispatch("logger/add","outlookMoveMail: " + folder);  
      this.getTokenBearer();                  
      try {
        if(this.useRest)
        {   
          let apiurl = await this.getRestUrl(mailItem);
          let result = await this.$http.post(
            apiurl + "/move",
            {
              DestinationId: folder
            },
            {
              withCredentials: false,
              headers: { Authorization: "Bearer " + this.token_bearer }
            }
          );
          return result.data.Id;
        }
      } catch (error) {
        this.$store.dispatch("logger/add","ERROR outlookMoveMail " + folder);
        return "";
      }   
    },
    outlookRemoveAttachments: async function (mailItemId) {
      this.$store.dispatch("logger/add","outlookRemoveAttachments");
      this.getTokenBearer();                  
      try {
        if(this.useRest)
        {  
          let apiurl = await this.getRestUrl(mailItemId);
          let result = await this.$http.get(
            apiurl + "/attachments?$select=Name",
            {
              withCredentials: false,
              headers: { Authorization: "Bearer " + this.token_bearer }
            }
          );
          if (result.status == 200) {
            let attachments = result.data.value;
            for(let ix = 0; ix < attachments.length; ix++)
            {
              let attachment = attachments[ix];
              await this.outlookRemoveAttachment(mailItemId, attachment);
            }
          }
          return true;
        }
      } catch (error) {
        this.$store.dispatch("logger/add","ERROR outlookRemoveAttachments");
        return false;
      }   
    },
    outlookRemoveAttachment: async function (mailItemId, attachment) {
      this.$store.dispatch("logger/add","outlookRemoveAttachment");
      this.getTokenBearer();                  
      try {
        if(this.useRest)
        { 
          let apiurl = await this.getRestUrl(mailItemId);
          let result = await this.$http.delete(
            apiurl + "/attachments/" + attachment.Id,
            {
              withCredentials: false,
              headers: { Authorization: "Bearer " + this.token_bearer }
            }
          );
          if (result.status == 204) {
            return true;
          }
        }
      } catch (error) {
        this.$store.dispatch("logger/add","ERROR outlookRemoveAttachment");
        return false;
      }   
    },
    outlookAddAttachment: async function (mailItemId, name, content) {
      this.$store.dispatch("logger/add","outlookAddAttachment");
      this.getTokenBearer();                  
      try {
        let payload = {
          "@odata.type": "#Microsoft.OutlookServices.FileAttachment",
          Name: name,
          ContentBytes: content
        };
        let apiurl = await this.getRestUrl(mailItemId);
        let result = await this.$http.post(
          apiurl + "/attachments",
          payload,
          {
            withCredentials: false,
            headers: { Authorization: "Bearer " + this.token_bearer }
          }
        );
        return result;
      } catch (error) {
        this.$store.dispatch("logger/add","ERROR outlookAddAttachment");
        return null;
      }   
    },
    outlookUpdateMessage: async function (mailItemId, subject, contentType, content, toRecipients, ccRecipients, bccRecipients) {
      this.$store.dispatch("logger/add","outlookUpdateMessage");
      this.getTokenBearer();                  
      try {
        let payload = {
          Subject: subject,
          Body: {
            ContentType: contentType,
            Content: content
          },
          ToRecipients: toRecipients,
          CcRecipients: ccRecipients,
          BccRecipients: bccRecipients
        };
        let apiurl = await this.getRestUrl(mailItemId);
        let result = await this.$http.patch(apiurl, payload, {
          withCredentials: false,
          headers: { Authorization: "Bearer " + this.token_bearer }
        });
        return result.data.Id;
      } catch (error) {
        this.$store.dispatch("logger/add","ERROR outlookUpdateMessage");
        return "";
      }     
    },
    outlookUpdateRecipients: async function (mailItemId, toRecipients, ccRecipients, bccRecipients) {
      this.$store.dispatch("logger/add","outlookUpdateMessage");
      this.getTokenBearer();                  
      try {
        let payload = {
          ToRecipients: toRecipients,
          CcRecipients: ccRecipients,
          BccRecipients: bccRecipients
        };
        let apiurl = await this.getRestUrl(mailItemId);
        let result = await this.$http.patch(apiurl, payload, {
          withCredentials: false,
          headers: { Authorization: "Bearer " + this.token_bearer }
        });
        return result.data.Id;
      } catch (error) {
        this.$store.dispatch("logger/add","ERROR outlookUpdateMessage");
        return "";
      }     
    },
    outlookSend: async function (mailItemId) {
      this.$store.dispatch("logger/add","outlookSend");
      this.getTokenBearer();                  
      let apiurl = await this.getRestUrl(mailItemId);
      try {
        let result = await this.$http.post(
        apiurl + "/send",
        {},
        {
          withCredentials: false,
          headers: { Authorization: "Bearer " + this.token_bearer }
        });
        return result;
      } catch (error) {
        this.$store.dispatch("logger/add","ERROR outlookSEND");
        return null;
      }        
    },
    outlookSendMail: async function (mailItemId,  subject, contentType, content, toRecipients, ccRecipients, bccRecipients, sefosFile) {
      this.$store.dispatch("logger/add","outlookSend");
      this.getTokenBearer();                  
      let apiurl = await this.getRestUrl(mailItemId);
      try {
        let result = await this.$http.post(
        apiurl + "/sendMail",
        {
          message: {
            subject: subject,
            body: {
              "contentType": contentType,
              "content": content
            },
            toRecipients: toRecipients,
            ccRecipients: ccRecipients,
            bccRecipients: bccRecipients
          },
          attachments: [
            {
              "@odata.type": "#microsoft.graph.fileAttachment",
              "name": "SEFOS.txt",
              "contentType": "text/plain",
              "contentBytes": sefosFile
            }
          ],
          saveToSentItems: "false"
        },
        {
          withCredentials: false,
          headers: { Authorization: "Bearer " + this.token_bearer }
        });
        return result;
      } catch (error) {
        this.$store.dispatch("logger/add","ERROR outlookSEND");
        return null;
      }        
    },
    outlookDraftToSend: async function (mailItemId) {
      this.$store.dispatch("logger/add","outlookDraftToSend");
      let result = null;
      await this.getTokenBearer();
      try {
        if(this.useRest)
        { 
          result = await this.restDraftToSent(mailItemId);           
        }    
        return result;
      } catch (error) {
        this.$store.dispatch("logger/add","restDraftToSent error" + error.message);
        this.$store.dispatch("logger/add","ERROR outlookDraftToSend");
        return null;
      }     
    }
  }
};